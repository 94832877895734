import loadScript from '@/plugins/load-script';

export type GoogleMap = {
  getProjection: () => {
    fromLatLngToPoint: (point: number[]) => { x: number; y: number };
    fromPointToLatLng: (point: number[]) => { x: number; y: number };
  };
  getZoom: () => number;
  setCenter: (position: number[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maps: any;
};

export type GoogleMapMarker = {
  setVisible: (value: boolean) => void;
  setMap: (value: unknown) => void;
};

export type GoogleMapInfoWindow = {
  setContent: (element: unknown) => void;
  open: (map: unknown, marker?: unknown) => void;
  setPosition: (position: unknown) => void;
};

declare const google: GoogleMap;

const googleMapApiSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_KEY}`;
const defaultMapSettings = {
  zoom: 8,
  gestureHandling: 'greedy',
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
};

export const loadMap = async (
  mapElement: HTMLElement,
  infoElement: HTMLElement,
  center?: number[] | null,
  settings = defaultMapSettings,
) => {
  await loadScript({
    id: 'google-map',
    src: googleMapApiSrc,
  });

  const map = new google.maps.Map(mapElement, {
    ...settings,
    center: center ? new google.maps.LatLng(center[0], center[1]) : new google.maps.LatLng(55.75222, 37.61556),
  });

  const infoWindow = new google.maps.InfoWindow();
  infoWindow.setContent(infoElement);

  return [map, infoWindow];
};

export const showOnlineStations = (
  map: GoogleMap,
  stations: { id: string; type?: string; point?: number[] | null }[],
  onMarkerClick: (marker: GoogleMapMarker, stationId: string) => void,
) => {
  const markers: GoogleMapMarker[] = [];

  stations.forEach((station) => {
    if (!station.point) {
      return;
    }

    const marker = new google.maps.Marker({
      map,
      position: new google.maps.LatLng(station.point[0], station.point[1]),
      icon: {
        url: 'https://res.fuelup.ru/imgs/station4.png',
        scaledSize: new google.maps.Size(14, 14),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      },
    });

    markers.push(marker);

    marker.addListener('click', () => {
      onMarkerClick(marker, station.id);
    });
  });

  return markers;
};

export function addMarker(map: GoogleMap, point: number[], url: string) {
  return new google.maps.Marker({
    map,
    position: new google.maps.LatLng(point[0], point[1]),
    icon: {
      scaledSize: new google.maps.Size(14, 14),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 0),
      url,
    },
  });
}
