import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiAlert = _resolveComponent("UiAlert")!

  return (_ctx.title)
    ? (_openBlock(), _createBlock(_component_UiAlert, {
        key: 0,
        type: "error",
        class: _normalizeClass({ dense: !_ctx.$slots.description }),
        message: _ctx.title,
        closable: ""
      }, {
        description: _withCtx(() => [
          _createElementVNode("template", null, [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ])
        ]),
        _: 1
      }, 8, ["class", "message"]))
    : _createCommentVNode("", true)
}