
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { addMarker, GoogleMapMarker, loadMap, showOnlineStations, GoogleMap } from '@/helpers/useStationsMaps';
import { stationsGetMapStations } from '@/services/StationsService';
import StationsMapOnlineStation from './StationsMapOnlineStation.vue';
import StationsMapOfflineStation from './StationsMapOfflineStation.vue';

export default defineComponent({
  components: {
    StationsMapOnlineStation,
    StationsMapOfflineStation,
  },

  props: {
    center: {
      type: Array as PropType<number[] | null>,
      required: false,
    },
    highlightPoint: {
      type: Array as PropType<number[] | null>,
      required: false,
    },
  },

  setup(props) {
    const mapElement = ref();
    const stationElement = ref<HTMLElement | null>(null);
    let googleMap: GoogleMap;
    let onlineMarkers: GoogleMapMarker[] = [];
    let offlineMarkers: GoogleMapMarker[] = [];
    const isOnlineStationsVisible = ref(true);
    const isOfflineStationsVisible = ref(true);
    const isSelectedStationOnline = ref(false);

    const stationId = ref('');
    const stationName = ref('');
    const stationAddress = ref('');

    const loadStations = async () => {
      const stations = await stationsGetMapStations();
      return stations.stationsByES.items;
    };

    onMounted(() => {
      Promise.all([loadMap(mapElement.value, stationElement.value as HTMLElement, props.center), loadStations()]).then(
        ([[map, infoWindow], stationsList]) => {
          googleMap = map;

          onlineMarkers = showOnlineStations(map, stationsList, (marker, id) => {
            isSelectedStationOnline.value = true;
            infoWindow.open(map, marker);
            stationId.value = id;
          });

          if (props.highlightPoint) {
            const marker = addMarker(map, props.highlightPoint, 'https://res.fuelup.ru/imgs/station2.png');
            marker.clickable = false;
          }
        },
      );
    });

    watch(
      () => [isOfflineStationsVisible.value],
      () => {
        var mapValue = isOfflineStationsVisible.value ? googleMap : null;
        offlineMarkers.forEach((marker) => {
          marker.setMap(mapValue);
        });
      },
    );

    watch(
      () => [isOnlineStationsVisible.value],
      () => {
        var mapValue = isOnlineStationsVisible.value ? googleMap : null;
        onlineMarkers.forEach((marker) => {
          marker.setMap(mapValue);
        });
      },
    );

    return {
      mapElement,

      stationId,
      stationName,
      stationAddress,
      stationElement,

      isOnlineStationsVisible,
      isOfflineStationsVisible,
      isSelectedStationOnline,
    };
  },
});
