import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c21fbbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stations-map fill-height" }
const _hoisted_2 = {
  ref: "mapElement",
  class: "fill-height"
}
const _hoisted_3 = { style: {"position":"absolute","width":"0","height":"0","opacity":"0","overflow":"hidden"} }
const _hoisted_4 = { ref: "stationElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StationsMapOnlineStation = _resolveComponent("StationsMapOnlineStation")!
  const _component_StationsMapOfflineStation = _resolveComponent("StationsMapOfflineStation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_StationsMapOnlineStation, { stationId: _ctx.stationId }, null, 8, ["stationId"]), [
          [_vShow, _ctx.isSelectedStationOnline]
        ]),
        _withDirectives(_createVNode(_component_StationsMapOfflineStation, {
          name: _ctx.stationName,
          address: _ctx.stationAddress
        }, null, 8, ["name", "address"]), [
          [_vShow, !_ctx.isSelectedStationOnline]
        ])
      ], 512)
    ])
  ]))
}